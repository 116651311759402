



















































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import { usePromise } from 'vue-promised'
import { getModule } from 'vuex-module-decorators'

import AppInfo from '@/components/AppInfo.vue'
import Customer from '@/components/Customer.vue'
import Orders from '@/components/Orders.vue'
import Proofs from '@/components/Proofs.vue'
import RawData from '@/components/RawData.vue'
import store from '@/store'
import PaperCulture from '@/store/modules/paperCulture'
import Salesforce from '@/store/modules/salesforce'
import Zendesk from '@/store/modules/zendesk'
import { initializeAnalytics } from '@/utils/analytics'
import { outerHeight } from '@/utils/dom'

import SalesforceConnectButton from './components/SalesforceConnectButton.vue'

export default defineComponent({
  components: {
    AppInfo,
    Customer,
    Orders,
    Proofs,
    SalesforceConnectButton,
    RawData
  },
  setup() {
    // Show errors in the UI so the user knows if something weird is going on
    const vueError = ref<string>()
    const showVueError = ref(false)
    // TODO: Consider if we need to catch other types of errors, or if we should use Sentry's user feedback dialog
    const oldErrorHandler = Vue.config.errorHandler
    Vue.config.errorHandler = (errorObject, vm, info) => {
      if (errorObject?.stack) {
        // Show error and first 2 lines of stack trace
        vueError.value = errorObject.stack.split('\n').slice(0, 3).join('\n')
        // If another error occurs after the alert is dismissed, show the alert again
        showVueError.value = true
      }
      if (oldErrorHandler) {
        // Call existing error handler
        oldErrorHandler(errorObject, vm, info)
      } else {
        // Rethrow if there are no other handlers that may log to console
        throw errorObject
      }
    }

    const zendesk = getModule(Zendesk)
    const salesforce = getModule(Salesforce)
    const paperCulture = getModule(PaperCulture)

    const content = ref<HTMLElement>()

    /**
     * Resize the container to height, otherwise height will be automatically detected from the
     * outer height of the content container.
     *
     * Passing in height is useful for performance if we already know the height of content (e.g. from an observer).
     */
    async function resize(height?: number) {
      if (height) {
        await zendesk.resize(height)
      } else if (content.value) {
        await zendesk.resize(outerHeight(content.value))
      }
    }

    /**
     * Fetches data that requires Salesforce authentication.
     */
    async function fetchAuthenticated() {
      if (salesforce.accessToken) {
        await salesforce.fetchAll(zendesk.ticket.requester.email)
        if (salesforce.account?.clientId) {
          await paperCulture.fetchOrders({
            clientId: salesforce.account.clientId,
            authToken: salesforce.accessToken
          })
        }
      }
    }

    async function onConnectToSalesforce(accessToken: string) {
      if (accessToken) {
        // Once authenticated, fetch initial data
        await fetchAuthenticated()
      }
    }

    async function initialize() {
      // First, load the Salesforce Access Token to set up initial state
      await salesforce.loadAccessToken()

      // Get all initial data that doesn't require additional authentication
      await zendesk.fetchAll()

      // Defer loading analytics until we have the current user's ID, and to prioritize loading critical data.
      // However, we initialize ASAP once we have the user ID in case any other code wants to log analytics.
      // Otherwise, `window.gtag` won't be defined.
      // Don't wait for this before proceeding, because analytics can be initialized in the background.
      initializeAnalytics({
        user_id: zendesk.currentUser.id.toString()
      })

      // Once the Salesforce Access Token is available, we can fetch data from Salesforce and Mondrian
      store.watch(
        () => salesforce.accessToken as string,
        (accessToken: string) =>
          (loadingPromise.value = onConnectToSalesforce(accessToken))
      )
      await fetchAuthenticated()
    }

    onMounted(() => {
      // Set initial iframe height ASAP
      resize()
      if (content.value) {
        // Keep iframe's height updated as container's size changes
        new ResizeObserver((entries) => {
          // We already know the height from the blockSize.
          // Passing it to resize improves performance by allowing us to skip looking up the size again.
          resize(entries[0].borderBoxSize[0].blockSize)
        }).observe(content.value)
      }
    })

    const loadingPromise = ref(initialize())
    const promised = usePromise(loadingPromise)

    return {
      content,
      vueError,
      showVueError,
      ...promised,
      accessToken: computed(() => salesforce.accessToken),
      account: computed(() => salesforce.account),
      // TODO: Refactor stores to be normalized so each object can be resolved by ID
      orders: computed(() => paperCulture.orders),
      // TODO: Use separate promise for loading proofs so they can display first
      proofs: computed(() => salesforce.proofs),
      unpaidProofs: computed(() =>
        salesforce.proofs.filter((proof) => !proof.paid)
      ),
      salesforceOrders: computed(() => salesforce.orders),
      ticket: computed(() => zendesk.ticket),
      tickets: computed(() => zendesk.userTickets)
    }
  }
})
