var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseProofOrOrderItemListItem',{attrs:{"analytics-name":"Order item","salesforce-id":_vm.orderItem.salesforceId,"short-title":_vm.orderItem.item.shortTitle,"item-code":_vm.orderItem.item.code,"overall-status":_vm.orderItemData.overallStatus,"proof-id":_vm.orderItem.proofId,"is-add-on":_vm.orderItemData.isAddOn,"add-ons":_vm.proofAddOns,"proof":_vm.orderItemData.proof,"base-proof":_vm.baseProof,"delivery-type":_vm.orderItem.deliveryType,"days-late":_vm.orderItemData.daysLate,"days-delayed-by-approvals":_vm.orderItemData.daysDelayedByApprovals,"shipments":_vm.orderItemData.shipments}},[_vm._v(" "+_vm._s(_vm.orderItem.quantity)+" "),(
      (_vm.orderItemData.overallStatus === 'Shipped' ||
        _vm.orderItemData.overallStatus === 'Delivered') &&
      _vm.orderItem.deliveryType === 'Mail & Message'
    )?[_vm._v(" Mailed ")]:(
      (_vm.orderItemData.overallStatus === 'Shipped' ||
        _vm.orderItemData.overallStatus === 'Delivered') &&
      _vm.orderItemData.shipments.length &&
      _vm.orderItemData.shipments[0].trackingUrl
    )?_c('a',{class:_vm.orderItemData.isAddOn
        ? 'text-decoration-none text--darken-1 grey--text'
        : 'text--primary',attrs:{"href":_vm.orderItemData.shipments[0].trackingUrl,"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.orderItemData.overallStatus)}}):[_vm._v(" "+_vm._s(_vm.orderItemData.overallStatus)+" ")],(
      _vm.orderItemData.overallStatus.startsWith('Pending') ||
      _vm.orderItemData.overallStatus === 'At Customer'
    )?[(_vm.orderItemData.proofLastModifiedDate)?[_vm._v(" • Updated: "+_vm._s(_vm.formatDateDistanceToNowIfRecent(_vm.orderItemData.proofLastModifiedDate))+" ")]:_vm._e()]:(
      _vm.orderItemData.overallStatus === 'Shipped' && _vm.orderItemData.firstShipDate
    )?[_c('Tooltip',{attrs:{"top":"","analytics-name":"Ship date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatDateDistanceToNowIfRecent(_vm.orderItemData.firstShipDate))+" ")])]}},{key:"tooltip",fn:function(){return [_vm._v(" First Shipped: "+_vm._s(_vm.formatDateDistanceToNowIfRecent(_vm.orderItemData.firstShipDate))+" "),_c('br'),(
            _vm.orderItemData.promisedShipDate.getTime() !==
            _vm.orderItemData.adjustedPromisedShipDate.getTime()
          )?[_vm._v(" Promised (adjusted for edits): "+_vm._s(_vm.formatDateDistanceToNowIfRecent( _vm.orderItemData.adjustedPromisedShipDate ))+" "),_c('br')]:_vm._e(),_vm._v(" Promised: "+_vm._s(_vm.formatDateDistanceToNowIfRecent(_vm.orderItemData.promisedShipDate))+" ")]},proxy:true}])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }