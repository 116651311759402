



































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { difference } from 'lodash-es'
import { getModule } from 'vuex-module-decorators'

import { trackTogglePanel } from '@/components/mixins/analytics'

import PaperCulture from '../store/modules/paperCulture'
import Salesforce from '../store/modules/salesforce'
import Zendesk from '../store/modules/zendesk'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prettyPrintJson = (obj: any) => JSON.stringify(obj, null, 1)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cloneObjWithoutKeys = (obj: any, keys: string[]): any => {
  if (Object(obj) !== obj) {
    return obj
  } else if (Array.isArray(obj)) {
    return obj.map((o) => cloneObjWithoutKeys(o, keys))
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([k]) => !keys.includes(k))
      .map(([k, v]) => [k, cloneObjWithoutKeys(v, keys)])
  )
}

/**
 * Stringify a pretty print a Salesforce object from ts-force, but exclude non-queried data,
 * such as access tokens and API metadata.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prettyPrintSalesforce = (obj: any) =>
  prettyPrintJson(
    cloneObjWithoutKeys(obj, [
      '_TYPE_',
      '_client',
      '_modified',
      'attributes',
      'safeUpdateProxyHandler',
      'handleCompositeBatchGetResult',
      'handleCompositeGetResult',
      'handleCompositeUpdateResult',
      'setModified'
    ])
  )

export default defineComponent({
  setup() {
    const zendesk = getModule(Zendesk)
    const salesforce = getModule(Salesforce)
    const paperCulture = getModule(PaperCulture)

    const dataPanels = [
      ['Account', salesforce.account],
      ['Orders', paperCulture.orders],
      ['Additional Order Data', salesforce.orders],
      ['Proofs', salesforce.proofs],
      ['Ticket', zendesk.ticket],
      ['Ticket Fields', zendesk.ticketFields],
      ['Tickets', zendesk.tickets],
      ['Current Zendesk User', zendesk.currentUser]
    ]

    // Send analytics events when panels are expanded and collapsed.
    // The initial state of each `ref` matches what the Vuetify `VExpansionPanels` component sets in the model,
    // when all panel(s) are closed.
    // There's only 1 raw data panel. When the panel is closed, the component sets the model to `undefined`.
    const rawDataPanelIndex = ref()
    // With multiple data panels, when they're all closed, the component sets the model to `[]`
    const dataPanelsIndex: Ref<number[]> = ref([])
    watch(rawDataPanelIndex, (expandedIndex) => {
      trackTogglePanel(expandedIndex != undefined, 'Raw Data')
    })
    watch(dataPanelsIndex, (updatedIndices, oldIndices) => {
      let isExpanded: boolean
      let panelIndex: number
      if (updatedIndices.length > oldIndices.length) {
        isExpanded = true
        panelIndex = difference(updatedIndices, oldIndices)[0]
      } else {
        isExpanded = false
        panelIndex = difference(oldIndices, updatedIndices)[0]
      }
      trackTogglePanel(isExpanded, dataPanels[panelIndex][0] as string)
    })

    return {
      rawDataPanelIndex,
      dataPanelsIndex,
      dataPanels,
      prettyPrintSalesforce
    }
  }
})
