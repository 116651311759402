import {
  addBusinessDays,
  differenceInBusinessDays,
  differenceInCalendarDays
} from 'date-fns'
import { sortBy } from 'lodash-es'

import { sortPriorityFromTitleType } from '@/components/mixins/proofUtils'
import { Proof } from '@/generated'
import {
  Order,
  OrderItem,
  OrderItemOverallStatus,
  ProofRevision,
  Shipment,
  ShipmentStatus
} from '@/store/modules/types/order'

/**
 * List of `OrderItemOverallStatus` in order of importance within the context of an order.
 * When we derive a single status for an order, we use the first `OrderItemOverallStatus` from this list
 * that appears in the `Order`.
 */
export const ORDER_STATUS_BY_IMPORTANCE: OrderItemOverallStatus[] = [
  'At Customer',
  'Pending Customer Edits',
  'Pending Proof QA',
  'Waiting for Proof QA of Other Proofs',
  'Pending Shipment QA',
  'Preparing for Shipment',
  'Shipped',
  'Delivered',
  'Canceled'
]

export const SHIPMENT_STATUS_BY_IMPORTANCE: ShipmentStatus[] = [
  'On Hold',
  'Generating',
  'Awaiting QA',
  'Rendered',
  'Ready to Pull',
  'Submitted to Printer',
  'Printing',
  'Shipped',
  'Canceled'
]

// TODO: Add directly to OrderItem at ingestion or as computed fields with vuex-orm model
export interface OrderItemData {
  salesforceId: string
  promisedShipDate: Date
  adjustedPromisedShipDate: Date
  daysDelayedByApprovals: number
  overallStatus: OrderItemOverallStatus
  overallStatusImportanceIndex: number
  shipments: Shipment[]
  shipmentsIncludingCanceled: Shipment[]
  firstShipDate: Date | null
  /**
   * Number of days shipped late beyond the adjusted promised ship date.
   * If this is negative, the item shipped early.
   * For items that haven't shipped yet, this is a best case scenario, assuming the item shipped right now.
   */
  daysLate: number
  addOns: OrderItem[]
  proofLastModifiedDate: Date | null
  proof: Proof | null
  isAddOn: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const orderUtils = (order: Order) => {
  return {
    /**
     * Splits an order between the YYMMDD part and the daily counter.
     * YYMMDD##### becomes YYMMDD #####.
     *
     * This can be used to reformat the order number for better readability.
     */
    splitOrderNumber(orderNumber: string): string {
      return orderNumber.substring(0, 6) + ' ' + orderNumber.substring(6, 11)
    },
    /**
     * Groups add-ons after their parents, and for items within the same project,
     * orders the most notable add-ons first.
     */
    get sortedOrderItemsWithAddOns() {
      return sortBy(
        this.nonZeroQuantityOrderItems,
        // Group by project first to put related proofs next to each other
        (orderItem) => orderItem.proof?.projectId,
        // Within each project, the main proof is first, above any add-ons
        (orderItem) => orderItem.proof?.projectId !== orderItem.proof?.id,
        // Group by type, similar to frontend logic
        (orderItem) => sortPriorityFromTitleType(orderItem.item.shortTitle),
        // Higher quantities first, like frontend
        (orderItem) => -orderItem.quantity,
        // Higher prices first, like frontend
        (orderItem) => -orderItem.cost,
        // Finally, sort by title for consistency, to break any ties
        (orderItem) => orderItem.item.shortTitle
      )
    },
    get mainOrderItems() {
      return this.nonZeroQuantityOrderItems.filter(this.isMain, this)
    },
    get nonZeroQuantityOrderItems() {
      return order.orderItems.filter((orderItem) => orderItem.quantity > 0)
    },
    // TODO: If proofs are in the same shipment group, use max delay of any proofs in that group
    // TODO: For add-ons get this from the parent
    daysDelayedByApprovals(orderItem: OrderItem, proof: Proof | null): number {
      // Check 'Cancelled' on order item, not proof, because a proof that's reordered will be uncancelled
      if (
        !proof ||
        orderItem.proof?.type == 'Add-On' ||
        orderItem.status == 'Cancelled'
      ) {
        // Order items without a proof don't require manual approvals,
        // and "Cancelled" proofs aren't delayed.
        return 0
      } else {
        // If there is no approval date, project based off current date
        if (!proof.dateOrderItemCreated) {
          throw new Error(
            `Proof ID ${proof.proofId} missing dateOrderItemCreated from Salesforce`
          )
        }
        const approvalDate = proof.dateFirstApproved
          ? new Date(proof.dateFirstApproved)
          : new Date()
        // Orders can't be delayed by approvals if the proof was approved before ordering
        return approvalDate < proof.dateOrderItemCreated
          ? 0
          : differenceInBusinessDays(approvalDate, proof.dateOrderItemCreated)
      }
    },
    isApprovedByCustomer(orderItem: OrderItem): boolean {
      return (
        !orderItem.proof || this.bestRevision(orderItem)?.status == 'Approved'
      )
    },
    adjustedPromisedShipDate(orderItem: OrderItem, proof: Proof | null): Date {
      return addBusinessDays(
        // Parse in local time instead of UTC
        new Date(orderItem.promisedShipDate + 'T00:00'),
        this.daysDelayedByApprovals(orderItem, proof)
      )
    },
    /**
     * Returns all order shipments containing `orderItem`, sorted by created date (ascending).
     *
     * @param orderItem Order item to search shipments for.
     * @param shipments All shipments.
     */
    orderItemShipments(
      orderItem: OrderItem,
      shipments: Shipment[]
    ): Shipment[] {
      return shipments
        .filter((shipment) =>
          shipment.orderItems.some(
            (shipmentOrderItem) =>
              shipmentOrderItem.orderItemSalesforceId == orderItem.salesforceId
          )
        )
        .sort((a, b) => {
          if (a.created < b.created) return -1
          if (a.created > b.created) return 1
          return 0
        })
    },
    firstShipDate(
      orderItem: OrderItem,
      uncanceledOrderItemShipments: Shipment[]
    ): Date | null {
      const firstShipment = uncanceledOrderItemShipments[0]
      return firstShipment?.shipTime ? new Date(firstShipment.shipTime) : null
    },
    addOns(orderItem: OrderItem): OrderItem[] {
      return this.isMain(orderItem)
        ? order.orderItems.filter(
            (otherOrderItem) =>
              orderItem != otherOrderItem &&
              otherOrderItem.proof &&
              otherOrderItem.proof.projectId == orderItem.proof?.projectId
          )
        : []
    },
    /**
     * Main items may have add-ons or children. These are the primary items displayed.
     */
    isMain(orderItem: OrderItem) {
      return !this.isAddOn(orderItem)
    },
    isAddOn(orderItem: OrderItem) {
      return !!orderItem.proof && orderItem.proof.projectId != orderItem.proofId
    },
    orderItemData(
      orderItem: OrderItem,
      allShipments: Shipment[],
      proofs: Proof[]
    ): OrderItemData {
      // TODO: Optimize performance by creating a map of proofs first, or normalizing Vuex stores
      const proof =
        proofs.find((proof) => proof.proofId == orderItem.proofId) || null
      const shipmentsIncludingCanceled = this.orderItemShipments(
        orderItem,
        allShipments
      )
      const shipments = shipmentsIncludingCanceled.filter(
        (shipment) => shipment.status !== 'Canceled'
      )
      const overallStatus = this.orderItemOverallStatus(orderItem, allShipments)
      const firstShipDate = this.firstShipDate(orderItem, shipments)
      const adjustedPromisedShipDate = this.adjustedPromisedShipDate(
        orderItem,
        proof
      )
      // TODO: Consider adding business days late. Calendar days if early, business days if late, would look better.
      const daysLate =
        // Items without proofs don't appear in shipments
        (!orderItem.proof && orderItem.status == 'Shipped') ||
        // Legacy orders don't have shipments
        (!shipments.length && orderItem.status == 'Shipped') ||
        orderItem.status == 'Cancelled'
          ? // Items without Proofs don't have Shipments, and we don't care about the status of "Cancelled" Order Items.
            // It's possible for us to check if a shipment exists for a "Cancelled" Order Item anyways,
            // but it's simpler and less noisy to ignore them once "Cancelled" because it likely doesn't matter anymore.
            0
          : differenceInCalendarDays(
              // TODO: Should we add 1 business day to now as a projected ship date for unshipped items and update docs?
              firstShipDate || new Date(),
              adjustedPromisedShipDate
            )
      return {
        salesforceId: orderItem.salesforceId,
        // Parse in local time instead of UTC
        promisedShipDate: new Date(orderItem.promisedShipDate + 'T00:00'),
        adjustedPromisedShipDate,
        daysDelayedByApprovals: this.daysDelayedByApprovals(orderItem, proof),
        overallStatus: overallStatus,
        overallStatusImportanceIndex: ORDER_STATUS_BY_IMPORTANCE.indexOf(
          overallStatus
        ),
        shipments,
        shipmentsIncludingCanceled,
        firstShipDate,
        daysLate,
        addOns: this.addOns(orderItem),
        proofLastModifiedDate: proof?.lastModifiedDate || null,
        proof,
        isAddOn: this.isAddOn(orderItem)
      }
    },
    /**
     * Returns the main order items that should ship first, after taking into account delays from edits.
     * If multiple items are projected to be shipped on the same date, ones with the more important status
     * are listed first.
     */
    orderItemDataByShippingDate(
      shipments: Shipment[],
      proofs: Proof[]
    ): OrderItemData[] {
      return sortBy(
        order.orderItems
          .filter(this.isMain)
          .map((orderItem) => this.orderItemData(orderItem, shipments, proofs)),
        [
          'adjustedPromisedShipDate',
          'overallStatusImportanceIndex',
          'promisedShipDate',
          'firstShipDate'
        ]
      )
    },
    /**
     * If this is positive, at least 1 item is late or is projected to be late.
     * If this is negative, the everything may have shipped early, but it's also
     * possible that nothing has shipped and we are just returning a projection based on
     * shipping everything right now.
     */
    maxDaysLate(shipments: Shipment[], proofs: Proof[]): number {
      const allDaysLate = this.orderItemDataByShippingDate(shipments, proofs)
        .filter((item) => item.overallStatus !== 'Canceled')
        .map((item) => item.daysLate)
      return allDaysLate.length ? Math.max(...allDaysLate) : 0
    },
    orderOverallStatusCounts(): { [status in OrderItemOverallStatus]: number } {
      return order.orderItems.reduce((statuses, orderItem) => {
        const status = this.orderItemOverallStatus(orderItem, order.shipments)
        const existingCount = statuses[status]
        statuses[status] = existingCount ? existingCount + 1 : 1
        return statuses
      }, {} as { [status in OrderItemOverallStatus]: number })
    },
    /**
     * Single status for an order, derived from inspecting all order items and proofs.
     *
     * If some order items have progressed further than others, the earliest, or most important status
     * will be used. For example, if there's an item that was Delivered, another Pending QA, and another At Customer,
     * At Customer would take precedence because it's blocking and most important, even though Pending QA
     * is an earlier status.
     */
    get orderOverallStatus(): OrderItemOverallStatus | undefined {
      const statuses = new Set(Object.keys(this.orderOverallStatusCounts()))
      for (const status of ORDER_STATUS_BY_IMPORTANCE) {
        if (statuses.has(status)) {
          return status
        }
      }
    },
    activeShipmentsWithOrderItem(
      shipments: Shipment[],
      orderItemSId: string
    ): Shipment[] {
      return shipments
        .filter(
          (shipment) =>
            shipment.orderItems.some(
              (orderItem) => orderItem.orderItemSalesforceId == orderItemSId
            ) && shipment.status != 'Canceled'
        )
        .sort((a, b) => {
          // First, sort by status - most important status first
          const statusComparison =
            SHIPMENT_STATUS_BY_IMPORTANCE.indexOf(a.status) -
            SHIPMENT_STATUS_BY_IMPORTANCE.indexOf(b.status)
          if (statusComparison) return statusComparison
          // Lastly, sort by created - oldest first
          if (a.created < b.created) return -1
          if (a.created > b.created) return 1
          return 0
        })
    },
    bestRevision(orderItem: OrderItem): ProofRevision | undefined {
      return orderItem.proof?.revisions.find(
        (revision) =>
          revision.revisionNumber == orderItem.proof?.bestRevisionNumber
      )
    },
    orderItemOverallStatus(
      orderItem: OrderItem,
      shipments: Shipment[]
    ): OrderItemOverallStatus {
      switch (orderItem.status) {
        case 'In Progress': {
          // Hasn't been approved by Proof QA
          if (!orderItem.proof) {
            // This should be impossible, because if there's no proof, there's nothing to QA
            console.error(
              'Unexpected orderItem.status for order item without proof',
              orderItem.status,
              orderItem.salesforceId
            )
            return 'Unknown'
          }

          // The best revision's status will always help us determine the overall status of the order item
          // for an order item with a status of 'In Progress'.
          // From Paper Culture Mondrian orders API, reordered proofs have a blend of data from the reordered
          // proof object, and the best revision of the base proof.
          // The status of the reordered proof itself will always be 'Approved by Customer' (from MySQL),
          // so we have to look at the status of the revision that's returned, which will only
          // have the best revision of the base proof.
          const bestRevision = this.bestRevision(orderItem)
          if (!bestRevision) {
            console.error(
              `Best revision #${orderItem.proof.bestRevisionNumber} not found` +
                ` for order item ${orderItem.salesforceId}`
            )
            return 'Unknown'
          }
          switch (bestRevision.status) {
            case 'Approved':
              return 'Pending Proof QA'
            case 'At Customer - Pending Approval':
              return 'At Customer'
            case 'Edits Requested':
            case 'Edits in Progress':
              if (orderItem.proof.status == 'Pending Internal Edits') {
                return 'Pending Proof QA'
              } else {
                // Could be QA edits or customer-requested edits, but both cases will be sent back to the customer
                return 'Pending Customer Edits'
              }
            default:
              console.error(
                `Unexpected base proof best revision status ${bestRevision.status} for reordered proof with` +
                  ` orderItem.status ${orderItem.status}, sId ${orderItem.salesforceId}`
              )
              return 'Unknown'
          }
        }
        case 'Ready for Print': {
          // Passed Proof QA if applicable, and hasn't been shipped
          const orderItemShipments = this.activeShipmentsWithOrderItem(
            shipments,
            orderItem.salesforceId
          )
          if (!orderItemShipments.length) {
            // Shipment hasn't been created. Other proofs in shipment group aren't ready.
            // TODO: Once we can determine shipment groups ahead of time, reveal what it's blocked on (some place else)
            return 'Waiting for Proof QA of Other Proofs'
          } else {
            const mostImportantShipment = orderItemShipments[0]
            switch (mostImportantShipment.status) {
              case 'Submitted to Printer':
              case 'Printing':
                return 'Preparing for Shipment'
              default:
                return 'Pending Shipment QA'
            }
          }
        }
        case 'Cancelled':
          // We accidentally used the British spelling originally, but we correct it for the overall status
          return 'Canceled'
        case 'Shipped':
          // TODO: Look up delivery status to update to Delivered if applicable
          return 'Shipped'
        default:
          console.error(
            'Unexpected orderItem.status',
            orderItem.status,
            orderItem.salesforceId
          )
          return 'Unknown'
      }
    }
  }
}
