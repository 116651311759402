













































































import { defineComponent, PropType } from '@vue/composition-api'

import DenseExpansionPanel from '@/components/DenseExpansionPanel.vue'
import {
  formatDateDistanceToNowIfRecent,
  formatDateVerbose
} from '@/components/mixins/dateFormatters'
import { orderUtils } from '@/components/mixins/orderUtils'
import { pluralize } from '@/components/mixins/pluralize'
import SpecialInstructionListItems from '@/components/SpecialInstructionListItems.vue'
import { Order as SalesforceOrder, Proof } from '@/generated'
import { salesforceUrl } from '@/store/modules/salesforce'
import { Order } from '@/store/modules/types/order'

import IconWithTooltip from './IconWithTooltip.vue'
import OrderItemListItem from './OrderItemListItem.vue'
import OverallStatusIcon from './OverallStatusIcon.vue'

export default defineComponent({
  components: {
    DenseExpansionPanel,
    IconWithTooltip,
    OrderItemListItem,
    OverallStatusIcon,
    SpecialInstructionListItems
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    },
    salesforceOrder: {
      type: Object as PropType<SalesforceOrder>,
      required: true
    },
    showAddOns: {
      type: Boolean,
      required: true
    },
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ order }) {
    return {
      formatDateDistanceToNowIfRecent,
      formatDateVerbose,
      pluralize,
      salesforceUrl,
      ...orderUtils(order),
      shippingType: order.shippingType?.type
    }
  }
})
