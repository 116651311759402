var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tooltip',{attrs:{"bottom":"","disabled":!_vm.$slots.tooltip,"color":_vm.tooltipColor,"analytics-name":_vm.tooltipAnalyticsName,"analytics-params":_vm.tooltipAnalyticsParams},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('VChip',_vm._g(_vm._b({class:[
        'mr-2',
        'mb-2',
        { 'px-2': _vm.small },
        { 'font-weight-bold': !_vm.outlined && _vm.color !== 'white' }
      ],attrs:{"color":_vm.color,"text-color":_vm.textColor,"small":_vm.small,"outlined":_vm.outlined,"label":""}},'VChip',Object.assign({}, _vm.$attrs, attrs),false),Object.assign({}, _vm.$listeners, on)),[(_vm.icon)?_c('VIcon',{class:_vm.small ? 'mr-1' : 'mr-2',attrs:{"x-small":_vm.small,"small":!_vm.small,"color":_vm.textColor || (_vm.outlined && _vm.color) || 'grey'},domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e(),_vm._t("default")],2)]}},{key:"tooltip",fn:function(){return [_vm._t("tooltip")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }