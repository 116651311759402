






























import { defineComponent } from '@vue/composition-api'

import Tooltip from '@/components/Tooltip.vue'

export default defineComponent({
  components: {
    Tooltip
  },
  setup() {
    const env = process.env
    return {
      // Environment variables will be populated by the Netlify build.
      // Only variables prefixed by VUE_APP_* can be included, so the Netlify variables are remapped in `.env`.
      // https://docs.netlify.com/configure-builds/environment-variables/
      branch: env.VUE_APP_BRANCH || '[branch]',
      commitRef: env.VUE_APP_COMMIT_REF.substr(0, 7) || '[commit ref]'
    }
  }
})
