import axios from 'axios'
import { Module, MutationAction, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { Order } from '@/store/modules/types/order'

const PAPER_CULTURE_ORIGIN = 'https://www.paperculture.com'

const name = 'paperCulture'
if (module.hot) {
  store.unregisterModule(name)
}

@Module({ name, namespaced: true, dynamic: true, store })
export default class PaperCulture extends VuexModule {
  orders: Order[] = []

  @MutationAction({ mutate: ['orders'] })
  async fetchOrders(args: {
    clientId: number
    authToken: string
  }): Promise<{ orders: Order[] }> {
    const { clientId, authToken } = args
    const response = await axios.get<Order[]>(
      `${PAPER_CULTURE_ORIGIN}/api/v2/accounts/${clientId}/orders`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    return {
      orders: response.data
    }
  }
}
