



























import { defineComponent, PropType } from '@vue/composition-api'

import {
  formatDateDistanceToNowIfRecentShort,
  formatDateVerbose
} from '@/components/mixins/dateFormatters'

export interface TimelineItem {
  date: Date
  title: string
  caption?: string
  color?: string
  icon?: string
}

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TimelineItem[]>,
      required: true
    }
  },
  setup() {
    const escapedNewline = /\\n/g
    return {
      formatDateDistanceToNowIfRecentShort,
      formatDateVerbose,
      // TODO: Move logic to Vuex store as part of ingestion and schema normalization to centralize the fix
      /**
       * Literal \n appears in some ticket captions. This replaces \n with actual newlines.
       */
      replaceEscapedNewlines: (s: string) =>
        s && s.replace(escapedNewline, '\n')
    }
  }
})
