













































import { defineComponent, PropType } from '@vue/composition-api'

import {
  formatDateDistanceToNowIfRecentShort,
  formatDateVerbose
} from '@/components/mixins/dateFormatters'
import {
  CustomerSpecialInstruction,
  OrderSpecialInstruction,
  ProofSpecialInstruction
} from '@/generated'

export default defineComponent({
  props: {
    specialInstructions: {
      type: Array as PropType<
        | CustomerSpecialInstruction[]
        | ProofSpecialInstruction[]
        | OrderSpecialInstruction[]
      >,
      required: true
    }
  },
  setup() {
    return {
      formatDateVerbose,
      formatDateDistanceToNowIfRecentShort
    }
  }
})
