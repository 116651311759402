/**
 * Formats currency as USD. Takes care of rounding and prepending a $.
 *
 * @param amount Amount in USD
 */
export const formatCurrency = (amount: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount)
