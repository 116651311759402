import { mapValues, sortBy } from 'lodash-es'

import { Proof } from '@/generated'
import { FoilColor } from '@/store/modules/types/order'

// TODO: Fold functionality into proofs store once vuex-orm is used

/**
 * Main items may have add-ons or children. These are the primary items.
 */
export const isMain = (proof: Proof): boolean =>
  proof.proofId === proof.project?.projectId

export const sortPriorityFromTitleType = (title: string): number => {
  // Primary sort is by item type (derived from title). The type sort gives us organization and consistency.
  // Higher numbers will come first so we can easily sort by quantity and price desc.
  let sort
  if (title.includes('Extra')) {
    sort = 2
  } else if (title.includes('Envelope')) {
    // Comes last besides extras to make them next to extras but also since blanks are often free
    sort = 3
  } else if (title.includes('Foil')) {
    // Not currently an add-on but would come first if it was due to price, excitement, and quantity matches cards
    sort = 8
  } else if (title.includes('Thick') || title.includes('Paper')) {
    // Thickness / substrate next for similar reasons to foil
    sort = 7
  } else if (title.includes('Corners')) {
    sort = 6
  } else {
    // Default sort puts an add-on before envelopes, but after known add-ons.
    sort = 4
  }
  // Higher sorts displayed first
  return -sort
}

/**
 * Returns a sorted array of add-ons, with the most notable ones first.
 *
 * Proof sorting logic is modeled after customer-facing sort logic, but without using price, quantity,
 * which aren't available as part of a proof, since they're cart-related, not design-related.
 * This logic also doesn't use item or format data, since `Product2` (data isn't available to all license types).
 */
export const sortedAddOns = (addOns: Proof[]): Proof[] =>
  sortBy(
    addOns,
    // Proof's name is its item's short title, which will always be defined due to data constraints and the SOQL query
    (proof) => sortPriorityFromTitleType(proof.name as string),
    // TODO: Also use item and format data once `Product2` is migrated to a custom object like `Item__c`
    // Sort tied proofs by title for consistency
    (proof) => proof.name
  )

export const addOnsByProjectId = (
  proofs: Proof[]
): { [proofId: string]: Proof[] } =>
  proofs.reduce((obj, proof) => {
    const projectId = proof.project?.projectId
    if (!isMain(proof) && projectId) {
      obj[projectId] = (obj[projectId] || []).concat(proof)
    }
    return obj
  }, {} as { [proofId: string]: Proof[] })

export const sortedAddOnsByProjectId = (
  proofs: Proof[]
): { [proofId: string]: Proof[] } =>
  mapValues(addOnsByProjectId(proofs), sortedAddOns)

const circleCode = /^(AOF|POFL)\d+/
export const isCircle = (code: string): boolean => circleCode.test(code)

const ornamentCode = /^OCL\d+/
export const isOrnament = (code: string): boolean => ornamentCode.test(code)

const coasterCode = /^OBL\d+/
export const isCoaster = (code: string): boolean => coasterCode.test(code)

const memoryGameCode = /^OM\d+/
export const isMemoryGameCode = (code: string): boolean =>
  memoryGameCode.test(code)

/**
 * Approximates the following logic, but using code, for scenarios where format enum name isn't available:
 *
 * ```
 * formatEnumName.includes('CIRC_') ||
 * formatEnumName.includes('CIRCLE_') ||
 * formatEnumName.includes('ORNAMENTS_') ||
 * formatEnumName.includes('COASTER_') ||
 * formatEnumName == 'MEMORY_GAME'
 * ```
 *
 *
 * TODO: Remove once data model is improved to make format data easily accessible anywhere
 *
 * @param code Item code / SKU
 */
export const isCircular = (code: string): boolean =>
  isCircle(code) ||
  isOrnament(code) ||
  isCoaster(code) ||
  isMemoryGameCode(code)

const goldFoilCode = /^.*?-XYY([A-Z]{2})*FG/
const hasGoldFoil = (code: string): boolean => goldFoilCode.test(code)

const silverFoilCode = /^.*?-XYY([A-Z]{2})*FS/
const hasSilverFoil = (code: string): boolean => silverFoilCode.test(code)

const roseGoldFoilCode = /^.*?-XYY([A-Z]{2})*FR/
const hasRoseGoldFoil = (code: string): boolean => roseGoldFoilCode.test(code)

// TODO: Once complete item data is available, use `item.foilColor` and remove this `code`-based hack
export const codeFoilColor = (code: string): FoilColor | null => {
  if (hasGoldFoil(code)) {
    return 'GOLD'
  } else if (hasSilverFoil(code)) {
    return 'SILVER'
  } else if (hasRoseGoldFoil(code)) {
    return 'ROSE'
  } else {
    return null
  }
}

/**
 * Workflow status derived from inspecting a proof.
 */
export type ProofOverallStatus =
  | 'At Customer'
  | 'Pending Customer Edits'
  | 'Saved'
  | 'Approved by Customer'
  | 'Pending Proof QA'
  | 'Approved by Proof QA'
  | 'Shipped'
  | 'Canceled'
  // Should never happen. Indicates an error during occurred when looking up data.
  | 'Unknown'

/**
 * Returns a simplified status for a proof.
 *
 * If the proof has been reordered the base proof should be passed in.
 */
export const proofOverallStatus = (proof: Proof): ProofOverallStatus => {
  if (
    process.env.NODE_ENV === 'development' &&
    (proof.baseProofId || proof.baseProof)
  ) {
    throw new Error(
      `Cannot use reordered proof to get ProofOverallStatus; use base proof instead of ${proof}`
    )
  }
  switch (proof.status) {
    case 'At Customer':
    case 'Pending Customer Edits':
    case 'Saved':
    case 'Approved by Customer':
    case 'Shipped':
      return proof.status
    case 'Pending Internal Edits':
      return 'Pending Proof QA'
    case 'Pending QA':
      return 'Pending Proof QA'
    case 'Pending QA Edits':
      return 'Pending Customer Edits'
    case 'Approved by QA':
    case 'Rendered':
    case 'Rendering':
      return 'Approved by Proof QA'
    case 'Cancelled':
      // Fixes and remaps misspelling
      return 'Canceled'
    default:
      console.error(
        `Could not derive ProofOverallStatus from unexpected proof.status=${proof.status}`
      )
      return 'Unknown'
  }
}
