import { differenceInDays, format, formatDistanceToNow } from 'date-fns'

// TODO: Update data stores to always have native Date objects and remove this
const toDate = (date: string | Date): Date =>
  date instanceof Date ? date : new Date(date)

export const formatDateVerbose = (date: string | Date): string =>
  format(toDate(date), 'E, MMM d, yyyy, h:mm aa z')

export const formatDateDistanceToNowIfRecent = (
  date: string | Date,
  pastYearFormat = 'E, MMM d',
  oldFormat = 'MMM d, yyyy'
): string => {
  date = toDate(date)
  const daysOld = differenceInDays(new Date(), date)
  if (daysOld > 7) {
    // Use absolute date past 1 week because it's more useful when communicating with customers.
    // For recent orders, it's more useful to know how long ago the order was placed to understand
    // general order timing and customer expectations.
    return format(date, daysOld > 365 ? oldFormat : pastYearFormat)
  } else {
    return formatDistanceToNow(date, { addSuffix: true })
  }
}

const SHORT_FORMAT = 'M/d'

export const formatDateShort = (date: string | Date): string =>
  format(toDate(date), SHORT_FORMAT)

const SHORT_WITH_YEAR_FORMAT = 'M/d/yy'

export const formatDateShortWithYear = (date: string | Date): string =>
  format(toDate(date), SHORT_WITH_YEAR_FORMAT)

export const formatDateDistanceToNowIfRecentShort = (
  date: string | Date
): string => formatDateDistanceToNowIfRecent(date, SHORT_FORMAT, 'M/d/yy')

export const formatDateDistanceToNow = (date: string | Date): string =>
  formatDistanceToNow(toDate(date), { addSuffix: true })
