// Similar colors to the ones used in the official Zendesk UI
import { ChipColors } from '@/components/types/chip'
import { TicketStatus } from '@/store/modules/types/zendeskRest'

export const ticketStatusColors: Record<TicketStatus, ChipColors> = {
  new: {
    color: 'orange',
    textColor: 'brown darken-1'
  },
  open: {
    color: 'red',
    textColor: 'white'
  },
  hold: {
    color: 'blue-grey darken-4',
    textColor: 'white'
  },
  pending: {
    color: 'blue',
    textColor: 'white'
  },
  solved: {
    color: 'blue-grey',
    textColor: 'white'
  },
  closed: {
    color: 'grey',
    textColor: 'white'
  }
}
