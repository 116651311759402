






















import { defineComponent, PropType } from '@vue/composition-api'

import AddOnsSwitch from '@/components/AddOnsSwitch.vue'
import AppHeading from '@/components/AppHeading.vue'
import { recentOpenPanels } from '@/components/mixins/panelUtils'
import OrderComponent from '@/components/Order.vue'
import { Order as SalesforceOrder, Proof } from '@/generated'
import { Order } from '@/store/modules/types/order'

export default defineComponent({
  components: {
    AddOnsSwitch,
    AppHeading,
    Order: OrderComponent
  },
  props: {
    orders: {
      type: Array as PropType<Order[]>,
      required: true
    },
    salesforceOrders: {
      type: Array as PropType<SalesforceOrder[]>,
      required: true
    },
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ orders, salesforceOrders }) {
    const openPanels = recentOpenPanels(
      orders.map((order: Order) => new Date(order.created)),
      'Order'
    )

    return {
      salesforceOrder(order: Order) {
        return salesforceOrders.find(
          (salesforceOrder) => salesforceOrder.orderNumber == order.orderNumber
        )
      },
      openPanels,
      showAddOns: false
    }
  }
})
