
















import { defineComponent, PropType } from '@vue/composition-api'
import { getModule } from 'vuex-module-decorators'

import DenseExpansionPanel from '@/components/DenseExpansionPanel.vue'
import ProofListItem from '@/components/ProofListItem.vue'
import { Proof } from '@/generated'
import Salesforce from '@/store/modules/salesforce'

export default defineComponent({
  components: {
    DenseExpansionPanel,
    ProofListItem
  },
  props: {
    proof: {
      type: Object as PropType<Proof>,
      required: true
    },
    addOns: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ proof }) {
    const salesforce = getModule(Salesforce)
    const baseProofSalesforceId = proof.baseProof?.id
    const baseProof = baseProofSalesforceId
      ? salesforce.proofBySalesforceId(baseProofSalesforceId)
      : null

    return {
      baseProof
    }
  }
})
