import { Ref, ref, watch } from '@vue/composition-api'
import { differenceInCalendarDays } from 'date-fns'

import { trackTogglePanel } from '@/components/mixins/analytics'

export const recentOpenPanels = (
  dates: Date[],
  panelName: string
): Ref<number[]> => {
  const openPanels: Ref<number[]> = ref([])
  const now = new Date()
  for (const [i, date] of dates.entries()) {
    if (openPanels.value.push(i) > 3) {
      // Only open up to the first 3 at once
      break
    }
    const daysOld = differenceInCalendarDays(now, date)
    if (daysOld > 45) {
      // Only open recent orders
      break
    }
  }
  watch(openPanels, (updatedIndices, oldIndices) => {
    trackTogglePanel(updatedIndices.length > oldIndices.length, panelName)
  })
  return openPanels
}
