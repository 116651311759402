/**
 * Asynchronously loads JavaScript from a URL and returns a Promise.
 *
 * @param src URL to load the JavaScript from
 * @param defer Optionally set to `true` to defer parsing until all HTML is parsed.
 * If the script loads before HTML is parsed, `defer` will prevent script parsing from blocking rendering.
 */
export const loadScript = async (
  src: string,
  defer = false
): Promise<unknown> =>
  new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    if (defer) {
      script.defer = true
    }
    document.head.appendChild(script)
  })
