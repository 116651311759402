/**
 * Returns the element height including margins.
 */
export function outerHeight(element: HTMLElement): number {
  const height = element.offsetHeight,
    style = window.getComputedStyle(element)

  return (
    ['top', 'bottom']
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((side) => parseInt(style[`margin-${side}` as any]))
      .reduce((total, side) => total + side, height)
  )
}
