




















import { defineComponent, PropType } from '@vue/composition-api'

import SpecialInstructionListItems from '@/components/SpecialInstructionListItems.vue'

import IconWithTooltip from './IconWithTooltip.vue'

export interface Icon {
  icon: string
  color?: string
  tooltipColor?: string
  message: string
  label?: string | number
  component?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: { [key: string]: any }
}

export default defineComponent({
  components: {
    IconWithTooltip,
    // Dynamic component
    SpecialInstructionListItems
  },
  props: {
    icons: {
      type: Array as PropType<Icon[]>,
      required: true
    },
    right: {
      type: Boolean,
      default: false
    },
    tooltipAnalyticsName: {
      type: String as PropType<string | undefined>,
      default: undefined
    }
  }
})
