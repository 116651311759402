


































































































import { defineComponent, PropType } from '@vue/composition-api'
import { identity } from 'lodash-es'
import { getModule } from 'vuex-module-decorators'

import BaseProofOrOrderItemListItem from '@/components/BaseProofOrOrderItemListItem.vue'
import { formatDateDistanceToNowIfRecent } from '@/components/mixins/dateFormatters'
import { OrderItemData } from '@/components/mixins/orderUtils'
import Tooltip from '@/components/Tooltip.vue'
import Salesforce from '@/store/modules/salesforce'
import { OrderItem } from '@/store/modules/types/order'

export default defineComponent({
  components: {
    BaseProofOrOrderItemListItem,
    Tooltip
  },
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true
    },
    // TODO: Restructure and remove this once Vuex stores are normalized with vuex-orm
    orderItemData: {
      type: Object as PropType<OrderItemData>,
      required: true
    }
  },
  setup({ orderItemData }) {
    const salesforce = getModule(Salesforce)
    const baseProofSalesforceId = orderItemData.proof?.baseProof?.id
    const baseProof = baseProofSalesforceId
      ? salesforce.proofBySalesforceId(baseProofSalesforceId)
      : null

    const proofAddOns = orderItemData.addOns
      .map((addOn) => salesforce.proofByProofId(addOn.proof?.id as string))
      // Proof should always exist if we were able to know something was an add-on,
      // since a project and proof would be necessary to do so, so this is more of an extra precaution.
      .filter(identity)

    return {
      baseProof,
      proofAddOns,
      formatDateDistanceToNowIfRecent
    }
  }
})
