import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import { CUSTOM_ICONS } from '@/plugins/fontAwesome'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      // Zendesk Garden colors from https://zendeskgarden.github.io/react-components/theming/#palette.
      // Most colors in Zendesk are from this palette and shade 600 is a default.
      light: {
        // blue[600]
        primary: '#1F73B7',
        // grey[600]
        secondary: '#68737D',
        // blue[300]
        accent: '#ADCCE4',
        // red[600]
        error: '#CC3340',
        // kale[600]
        info: '#17494D',
        // green[600]
        success: '#038153',
        // yellow[600]
        warning: '#ED8F1C'
      }
    }
  },
  icons: {
    iconfont: 'faSvg',
    values: CUSTOM_ICONS
  }
})
