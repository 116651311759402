import { CUSTOM_ICONS } from '@/plugins/fontAwesome'

/**
 * Validates if an icon is valid.
 * Use as validator for an icon prop.
 *
 * Icon must be prefixed by `$` and registered in `fontAwesome.ts`.
 *
 * Theoretically, we could provide a object with a type and validator to use directly,
 * but that doesn't play nicely with the `vue/require-prop-types` ESLint rule.
 */
export const iconValidator = (icon: string): boolean =>
  icon.indexOf('$') === 0 && !!CUSTOM_ICONS[icon.substring(1)]
