










import { defineComponent, PropType } from '@vue/composition-api'

import IconWithTooltip from '@/components/IconWithTooltip.vue'
import { formatDateDistanceToNowIfRecent } from '@/components/mixins/dateFormatters'
import { pluralize } from '@/components/mixins/pluralize'
import { ProofOverallStatus } from '@/components/mixins/proofUtils'
import { OrderItemOverallStatus } from '@/store/modules/types/order'

export default defineComponent({
  components: { IconWithTooltip },
  props: {
    status: {
      type: String as PropType<ProofOverallStatus | OrderItemOverallStatus>,
      required: true
    },
    daysLate: {
      type: Number,
      default: 0
    },
    proofLastModifiedDate: {
      type: Date as PropType<Date | null>,
      default: null
    },
    tooltipAnalyticsName: {
      type: String as PropType<string | undefined>,
      default: undefined
    }
  },
  setup({ status, daysLate, proofLastModifiedDate }) {
    const color = (() => {
      if (daysLate > 0) {
        return 'red'
      } else if (status == 'At Customer') {
        return 'orange'
      } else if (status == 'Canceled') {
        return null
      } else {
        return 'green'
      }
    })()

    const icon = (() => {
      switch (status) {
        case 'At Customer':
          return '$user-clock'
        case 'Pending Customer Edits':
          return '$pen-square'
        case 'Saved':
          // Proof that was Saved For Later from personalization
          return '$save'
        case 'Approved by Customer':
          // Proof that's been approved, but not paid for
          return '$check-square'
        case 'Approved by Proof QA':
          // Proof status only. Order item status would keep advancing.
          // May be applicable on an unpaid reorder, if using the base proof's status.
          return '$thumbs-up'
        case 'Pending Proof QA':
        case 'Waiting for Proof QA of Other Proofs':
        case 'Pending Shipment QA':
          return '$search'
        case 'Preparing for Shipment':
          return '$print'
        case 'Shipped':
          return daysLate < 0 ? '$shipping-fast' : '$truck'
        case 'Delivered':
          return '$home'
        case 'Canceled':
          return '$times-circle'
        case 'Unknown':
        default:
          return '$question-circle'
      }
    })()

    const isShipped = status == 'Shipped' || status == 'Delivered'
    // Message built with script vs template, to conditionally append a comma without extra whitespace,
    // and to simplify the template.
    let message = status
    let info
    if (daysLate > 0) {
      if (isShipped) {
        info = `${pluralize(daysLate, 'day')} late`
      } else {
        info = `- projected to be late by at least ${pluralize(
          daysLate,
          'day'
        )}`
      }
    } else if (isShipped) {
      if (daysLate < 0) {
        info = `${pluralize(Math.abs(daysLate), 'day')} early`
      } else {
        info = `on promised ship date`
      }
    }
    if (info) {
      message += ` ${info}`
    }
    if (
      proofLastModifiedDate &&
      new Set([
        'At Customer',
        'Pending Customer Edits',
        'Pending Proof QA'
      ]).has(status)
    ) {
      // If the proof hasn't been approved, give an indication of how long it's been since the last update.
      // This is useful to see if it's been stuck in our queues for a while, or stuck at the customer.
      message += `, updated ${formatDateDistanceToNowIfRecent(
        proofLastModifiedDate
      )}`
    }

    return {
      color,
      icon,
      isShipped,
      message,
      pluralize
    }
  }
})
