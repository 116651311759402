import { loadScript } from '@/utils/script'

const GTAG_ID = 'G-NDHWHPL5JN'

/**
 * Load the Google Global site tag (gtag.js) for Hashi.
 */
const loadGtag = () =>
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`)

/**
 * Initializes all analytics and returns a `Promise` that resolves once all scripts have been loaded.
 * It's safe to start logging analytics immediately after this is called, even before the `Promise`
 * has been resolved.
 *
 * This should only be called once, during app setup.
 */
export const initializeAnalytics: (config?: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => Promise<unknown> = (config: { [key: string]: any } = {}) => {
  // Set up `dataLayer` and `gtag` dynamically, instead of using the default inline code.
  // This allows us to include analytics without any template changes.
  window.dataLayer = window.dataLayer || []
  // eslint-disable-next-line no-global-assign
  window.gtag = function () {
    // Rest params break Gtag.js. This pushes Arguments, just like the typical Gtag code does.
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultConfig: { [key: string]: any } = {}
  if (process.env.NODE_ENV === 'development') {
    defaultConfig.debug_mode = true
  }
  if (window.location.protocol === 'https:') {
    // Hashi will be included in an iframe within Zendesk from a third-party context.
    // With default SameSite cookie settings, cookies will be blocked in this context.
    // We need to add the SameSite=None flag to allow Google Analytics to work, and this also requires the Secure flag.
    // Therefore, analytics cookies won't work from http://localhost:8080 because it's not secure.
    // https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
    defaultConfig.cookie_flags = 'SameSite=None;Secure'
  }
  gtag('config', GTAG_ID, Object.assign({}, defaultConfig, config))
  return loadGtag()
}
