
























import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { sortBy } from 'lodash-es'

import AppHeading from '@/components/AppHeading.vue'
import CanceledSwitch from '@/components/CanceledSwitch.vue'
import { recentOpenPanels } from '@/components/mixins/panelUtils'
import { isMain, sortedAddOnsByProjectId } from '@/components/mixins/proofUtils'
import ProofExpansionPanel from '@/components/ProofExpansionPanel.vue'
import { Proof } from '@/generated'

export default defineComponent({
  components: {
    AppHeading,
    CanceledSwitch,
    ProofExpansionPanel
  },
  props: {
    proofs: {
      type: Array as PropType<Proof[]>,
      required: true
    }
  },
  setup({ proofs }) {
    const openPanels = recentOpenPanels(
      proofs.map((proof: Proof) => proof.lastModifiedDate as Date),
      'Proof'
    )

    const addOnsByProjectId = sortedAddOnsByProjectId(proofs)
    const showCanceled = ref(false)
    const mainProofs = computed(() =>
      sortBy(
        proofs.filter(isMain),
        (proof: Proof) => -(proof.lastModifiedDate as Date).getTime()
      )
    )
    return {
      mainProofs,
      filteredMainProofs: computed(() =>
        mainProofs.value.filter(
          (proof) => showCanceled.value || proof.status !== 'Cancelled'
        )
      ),
      addOns: (proof: Proof): Proof[] =>
        (proof.project?.projectId &&
          addOnsByProjectId[proof.project?.projectId]) ||
        [],
      openPanels,
      showCanceled
    }
  }
})
