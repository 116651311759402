







import { defineComponent, ref } from '@vue/composition-api'
import { getModule } from 'vuex-module-decorators'

import { trackLogin } from '@/components/mixins/analytics'
import Salesforce from '@/store/modules/salesforce'

export default defineComponent({
  setup() {
    const salesforce = getModule(Salesforce)
    const isConnecting = ref(false)

    async function connectSalesforce() {
      isConnecting.value = true
      await salesforce
        .getAccessToken()
        .finally(() => (isConnecting.value = false))
      trackLogin({
        method: 'Salesforce'
      })
    }

    return {
      connectSalesforce,
      isConnecting
    }
  }
})
