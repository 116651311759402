








import { defineComponent, ref, watch } from '@vue/composition-api'

import BaseInlineSwitch from '@/components/BaseInlineSwitch.vue'
import { trackToggleCanceled } from '@/components/mixins/analytics'

export default defineComponent({
  components: { BaseInlineSwitch },
  model: {
    event: 'toggle'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  setup({ value, sectionName }, { emit }) {
    const showCanceled = ref(value)
    watch(showCanceled, (shown) => {
      emit('toggle', shown)
      trackToggleCanceled(shown, sectionName)
    })
    return {
      showCanceled
    }
  }
})
