















import { defineComponent, PropType } from '@vue/composition-api'
import { capitalize } from 'lodash-es'

import ChipLabel from '@/components/ChipLabel.vue'
import { ticketStatusColors } from '@/components/mixins/tickets'
import {
  Ticket as RestTicket,
  TicketStatus
} from '@/store/modules/types/zendeskRest'

export default defineComponent({
  components: { ChipLabel },
  props: {
    tickets: {
      type: Array as PropType<RestTicket[]>,
      required: true
    }
  },
  setup({ tickets }) {
    const statusCounts = tickets.reduce((counts, ticket) => {
      counts[ticket.status] = (counts[ticket.status] || 0) + 1
      return counts
    }, {} as { [status: string]: number })
    const statuses: TicketStatus[] = [
      'new',
      'open',
      'hold',
      'pending',
      'solved',
      'closed'
    ].filter((status) => statusCounts[status]) as TicketStatus[]
    return {
      statuses,
      statusColors: ticketStatusColors,
      statusCounts,
      /** Expands hold to On Hold, and capitalizes all other statuses. */
      statusLabel: (status: TicketStatus) =>
        (status === 'hold' ? 'On ' : '') + capitalize(status)
    }
  }
})
