



























import { defineComponent, PropType } from '@vue/composition-api'

import BaseProofOrOrderItemListItem from '@/components/BaseProofOrOrderItemListItem.vue'
import {
  formatDateDistanceToNowIfRecent,
  formatDateVerbose
} from '@/components/mixins/dateFormatters'
import { isMain, proofOverallStatus } from '@/components/mixins/proofUtils'
import Tooltip from '@/components/Tooltip.vue'

import { Proof } from '../generated'

export default defineComponent({
  components: {
    BaseProofOrOrderItemListItem,
    Tooltip
  },
  props: {
    proof: {
      type: Object as PropType<Proof>,
      required: true
    },
    baseProof: {
      type: Object as PropType<Proof | null>,
      default: null
    },
    addOns: {
      type: Array as PropType<Proof[]>,
      default: () => []
    }
  },
  setup({ baseProof, proof }) {
    const overallStatus = proofOverallStatus(baseProof || proof)
    return {
      isMain,
      overallStatus,
      formatDateVerbose,
      formatDateDistanceToNowIfRecent
    }
  }
})
