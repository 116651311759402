








import { defineComponent, ref, watch } from '@vue/composition-api'

import BaseInlineSwitch from '@/components/BaseInlineSwitch.vue'
import { trackToggleAddOns } from '@/components/mixins/analytics'

export default defineComponent({
  components: { BaseInlineSwitch },
  model: {
    event: 'toggle'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    sectionName: {
      type: String,
      required: true
    }
  },
  setup({ value, sectionName }, { emit }) {
    const showAddOns = ref(value)
    watch(showAddOns, (shown) => {
      emit('toggle', shown)
      trackToggleAddOns(shown, sectionName)
    })
    return {
      showAddOns
    }
  }
})
