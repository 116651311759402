









import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

import { TooltipParams, trackViewTooltip } from '@/components/mixins/analytics'

export default defineComponent({
  props: {
    analyticsName: {
      type: String,
      required: true
    },
    analyticsParams: {
      type: Object as PropType<TooltipParams>,
      default: () => ({})
    }
  },
  setup({ analyticsName, analyticsParams }) {
    const isActive = ref(false)
    watch(isActive, (show) => {
      if (show) {
        trackViewTooltip(analyticsName, analyticsParams)
      }
    })
    return {
      isActive
    }
  }
})
