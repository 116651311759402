










import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  model: {
    event: 'toggle'
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    // e.g. add-ons
    label: {
      type: String,
      required: true
    }
  },
  setup({ value }, { emit }) {
    const show = ref(value)
    watch(show, (shown) => {
      emit('toggle', shown)
    })
    return {
      show
    }
  }
})
