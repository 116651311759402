























import { defineComponent, PropType } from '@vue/composition-api'

export interface ListItemAction {
  title: string
  // TODO: Update interface to be more flexible. Some actions may fire events and launch modals.
  link: string
}

export default defineComponent({
  props: {
    actions: {
      type: Array as PropType<ListItemAction[]>,
      required: true
    }
  }
})
