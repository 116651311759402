import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/vue'
import { sortBy } from 'lodash-es'
import {
  Action,
  Module,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators'

import store from '@/store'
import { snakeKeysToCamelCase } from '@/utils/camelCase'

import { Ticket as RestTicket } from './types/zendeskRest'
import TicketField = ZAFClient.TicketField
import Ticket = ZAFClient.Ticket
import User = ZAFClient.User
import ResizeResponse = ZAFClient.ResizeResponse
import Dimensions = ZAFClient.Dimensions
import InstancesCreateResponse = ZAFClient.InstancesCreateResponse

interface BulkTicketContextResponse {
  currentUser: User
  ticket: Ticket
  ticketFields: TicketField[]
}

interface CreateModalOptions {
  url: string
  size?: Dimensions
}

// Declaring outside the module ensures the client is accessible in a `@MutationAction`, where `this` isn't bound
const client = (() => {
  const zafClient = ZAFClient.init()

  if (!zafClient) {
    // Return test data when testing the app outside of Zendesk, where ZAFClient doesn't work
    return ({
      get: async (fields: string | string[]) => {
        fields = Array.isArray(fields) ? fields : [fields]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: { [key: string]: any } = {
          errors: {}
        }
        fields.forEach((field) => {
          let data = {}
          switch (field) {
            // Return limited data testing
            case 'currentUser':
              data = {
                alias: 'Jamie',
                email: 'zendesk@paperculture.com',
                id: 824312358,
                name: 'Zendesk Admin',
                tags: ['pc-team']
              }
              break
            case 'ticket':
              data = {
                subject: 'Test ticket subject',
                description: `Here's the description of the ticket, which may contain a transcription.`,
                tags: [],
                requester: {
                  // Other modules use email to look up additional customer data
                  name: 'Jon Wu',
                  email: 'jon@paperculture.com',
                  identities: [
                    {
                      deliverableState: 'deliverable',
                      id: 100,
                      primary: true,
                      type: 'google',
                      undeliverableCount: 0,
                      userId: 783976628,
                      value: 'jon@paperculture.com',
                      verified: true
                    },
                    {
                      id: 101,
                      primary: true,
                      type: 'phone_number',
                      userId: 1234567890,
                      // Phone number can be cross-referenced with order data
                      value: '+15555551234',
                      verified: true
                    }
                  ]
                }
              }
              break
            case 'ticketFields':
              data = []
              break
          }
          response[field] = data
        })
        return response
      },
      invoke: async () => Promise.resolve(),
      request: async (url: string) => {
        if (url.indexOf('/tickets/requested.json') !== -1) {
          return Promise.resolve({
            tickets: [
              // Partial stubbed ticket content for testing
              {
                url:
                  'https://paperculture.zendesk.com/api/v2/tickets/12345.json',
                id: 12345,
                via: {
                  channel: 'email',
                  source: {
                    from: {
                      address: 'dev+testing@paperculture.com',
                      name: 'Test Account'
                    },
                    to: {
                      name: 'Paper Culture',
                      address: 'contact@paperculture.com'
                    }
                  }
                },
                created_at: '2020-10-27T02:25:55Z',
                updated_at: '2020-10-27T15:18:49Z',
                type: 'incident',
                subject: 'Test Ticket',
                raw_subject: 'Test Ticket',
                description:
                  'Hi there!\nThis is just a hard-coded ticket for testing.',
                priority: 'urgent',
                status: 'new',
                recipient: 'contact@paperculture.com',
                tags: ['negative'],
                custom_fields: [],
                satisfaction_rating: { score: 'unoffered' },
                comment_count: 2,
                fields: []
              }
            ]
          })
        } else {
          return Promise.resolve()
        }
      }
    } as unknown) as ZAFClient.Client
  }

  return zafClient
})()

const name = 'zendesk'
if (module.hot) {
  store.unregisterModule(name)
}

@Module({ name, namespaced: true, dynamic: true, store })
export default class Zendesk extends VuexModule {
  // Expose `request` for other modules or services to use with secure parameters,
  // or for cross-origin requests that don't support CORS.
  // If a normal AJAX request is possible, don't use this because it's subject to Zendesk rate limits.
  get request(): {
    <T>(url: string): Promise<T>
    <T>(options: ZAFClient.RequestOptions): Promise<T>
  } {
    return client.request.bind(client)
  }

  currentUser: User = {} as User
  ticket: Ticket = {} as Ticket
  ticketFields: TicketField[] = []
  tickets: RestTicket[] = []

  /**
   * Fetch data for the current user and ticket.
   */
  @MutationAction({ mutate: ['currentUser', 'ticket', 'ticketFields'] })
  async fetchCurrentUserAndTicket(): Promise<BulkTicketContextResponse> {
    const response = (await client.get([
      'currentUser',
      'ticket',
      'ticketFields'
    ])) as BulkTicketContextResponse

    datadogRum.setUser({
      id: response.currentUser.id.toString(),
      email: response.currentUser.email,
      name: response.currentUser.name
    })
    const id = response.currentUser.id.toString()
    const email = response.currentUser.email
    const name = response.currentUser.name
    datadogRum.setUser({
      id,
      email,
      name
    })
    datadogLogs.setLoggerGlobalContext({
      // https://docs.datadoghq.com/logs/processing/attributes_naming_convention/#user-related-attributes
      usr: {
        id,
        email,
        name
      }
    })
    Sentry.setUser({
      id: id,
      email,
      username: name
    })
    Sentry.addBreadcrumb({
      category: 'zendesk',
      message: 'View ticket ' + response.ticket.id,
      data: {
        ticket: response.ticket
      }
    })
    clarity('set', 'id', id)
    clarity('set', 'email', email)
    clarity('set', 'name', name)

    return response
  }

  @Action
  async fetchAll(): Promise<void> {
    await this.fetchCurrentUserAndTicket()
    await this.fetchTickets()
  }

  /**
   * Fetch the most recently updated 100 tickets requested by the requester of the current ticket.
   */
  @MutationAction({ mutate: ['tickets'] })
  async fetchTickets(): Promise<{ tickets: Readonly<RestTicket[]> }> {
    const requesterId = (this.state as Zendesk).ticket.requester.id
    const response = await client.request(
      `/api/v2/users/${requesterId}/tickets/requested.json?` +
        new URLSearchParams({
          include: 'comment_count',
          // In case there are more tickets than we can get, make sure we get all the ones with recent action first
          sort_by: 'updated_at',
          sort_order: 'desc'
        }).toString()
    )
    // Data for `tickets` won't be modified, so `Object.freeze` optimizes performance by disabling reactivity
    const tickets = Object.freeze(
      sortBy(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (response as any).tickets.map(snakeKeysToCamelCase),
        (ticket) => ticket.createdAt
      ) as RestTicket[]
    )
    return {
      tickets
    }
  }

  /**
   * Resizes the iframe container in Zendesk.
   *
   * This should be called any time the height of the content changes.
   * If this is set to a value that's shorter than the iframe's content, there will be a scrollbar.
   */
  @Action
  async resize(height: number): Promise<ResizeResponse> {
    return client.invoke('resize', { height })
  }

  @Action
  async createModal(
    options: CreateModalOptions
  ): Promise<InstancesCreateResponse> {
    return client.invoke('instances.create', {
      location: 'modal',
      ...options
    })
  }

  /**
   * Filter out non-support tickets as they don't reflect user intent.
   * This returns tickets that represent a user's attempts to contact support.
   */
  get userTickets(): RestTicket[] {
    return this.tickets.filter(
      (ticket) => ticket.tags.indexOf('delighted') === -1
    )
  }
}
