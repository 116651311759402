
































































































import { defineComponent, PropType } from '@vue/composition-api'

import IconsWithTooltips, { Icon } from '@/components/IconsWithTooltips.vue'
import ListItemActions, {
  ListItemAction
} from '@/components/ListItemActions.vue'
import {
  formatDateDistanceToNowIfRecent,
  formatDateShort
} from '@/components/mixins/dateFormatters'
import { pluralize } from '@/components/mixins/pluralize'
import {
  codeFoilColor,
  isCircular,
  ProofOverallStatus
} from '@/components/mixins/proofUtils'
import OverallStatusIcon from '@/components/OverallStatusIcon.vue'
import Tooltip from '@/components/Tooltip.vue'
import { Proof, ProofRevision, Shipment } from '@/generated'
import { salesforceUrl } from '@/store/modules/salesforce'
import {
  OrderItemDeliveryType,
  OrderItemOverallStatus
} from '@/store/modules/types/order'

export default defineComponent({
  components: {
    IconsWithTooltips,
    ListItemActions,
    OverallStatusIcon,
    Tooltip
  },
  props: {
    // Singular name in sentence case, e.g. Order item
    analyticsName: {
      type: String,
      required: true
    },
    // Salesforce ID of the proof or order item, used to create a link to the object
    salesforceId: {
      type: String,
      required: true
    },
    // Short title of the item. Typically appears as the name in Salesforce data.
    shortTitle: {
      type: String,
      required: true
    },
    itemCode: {
      type: String,
      required: true
    },
    overallStatus: {
      type: String as PropType<ProofOverallStatus | OrderItemOverallStatus>,
      required: true
    },
    // Can't get this from proof because some order items may not have a proof
    proofId: {
      type: String,
      required: true
    },
    isAddOn: {
      type: Boolean,
      required: true
    },
    addOns: {
      type: Array as PropType<Proof[]>,
      required: true
    },
    // Won't be populated for order items without a proof (e.g. Static / non-personalized items)
    proof: {
      type: Object as PropType<Proof | null>,
      default: null
    },
    // Parent proof containing revisions - only defined for reorders
    baseProof: {
      type: Object as PropType<Proof | null>,
      default: null
    },
    // Delivery type for order item, which can also be populated for a proof by deriving
    // what an order item's delivery type would eventually be, based on context (e.g. attributes or add-ons).
    deliveryType: {
      type: String as PropType<OrderItemDeliveryType | null>,
      default: null
    },
    // Only relevant for (paid) items with a promised ship date.
    // Negative number means the item is early.
    daysLate: {
      type: Number,
      default: 0
    },
    // Only relevant for (paid) items with a promised ship date
    daysDelayedByApprovals: {
      type: Number,
      default: 0,
      validator: (daysDelayed: number) => daysDelayed >= 0
    },
    shipments: {
      type: Array as PropType<Shipment[]>,
      default: () => [] as Shipment[]
    }
  },
  setup({
    addOns,
    baseProof,
    daysDelayedByApprovals,
    deliveryType,
    itemCode,
    proof,
    shipments
  }) {
    // Icons are grouped by color and importance.
    // Least important icons and ones without colors are added last.
    const icons: Icon[] = []
    const numShipments = shipments.length
    if (numShipments > 1) {
      icons.push({
        icon: '$redo',
        color: 'red',
        message: `Shipped ${pluralize(numShipments, 'time')}`
      })
    }
    if (proof?.express) {
      icons.push({
        icon: '$fast-forward',
        color: 'yellow darken-2',
        message: 'Express Approval (no designer review)'
      })
    }
    const numRevisions = proof?.revisions?.length || 0
    if (numRevisions > 1) {
      const numEdits = numRevisions - 1
      icons.push({
        icon: '$pen-square',
        color: daysDelayedByApprovals ? 'orange' : 'blue',
        // TODO: Provide info about the revisions
        message:
          `${pluralize(numEdits, 'edit')}` +
          (daysDelayedByApprovals
            ? `, delayed order by ${pluralize(
                daysDelayedByApprovals,
                'business day'
              )}`
            : ''),
        label: numEdits
      })
    }
    const numProofSpecialInstructions = proof?.specialInstructions?.length || 0
    if (numProofSpecialInstructions) {
      icons.push({
        icon: '$sticky-note',
        color: 'blue',
        message: pluralize(
          numProofSpecialInstructions,
          'proof special instruction'
        ),
        // Darken tooltip background to improve legibility, and for consistency with other special instruction tooltips
        tooltipColor: 'grey darken-4',
        // TODO: Make heading formatting consistent with customer and order special instructions
        component: 'SpecialInstructionListItems',
        data: {
          specialInstructions: proof?.specialInstructions,
          dark: true
        }
      })
    }
    // TODO: Calculate delay and consider if it should affected the adjusted promised ship date
    const revisionsCreatedAfterFirstApproval =
      proof?.revisions?.filter(
        (revision: ProofRevision) =>
          proof.dateFirstApproved &&
          revision.createdDate &&
          proof.dateFirstApproved.getTime() < revision.createdDate.getTime()
      ) || []
    const numRevisionsAfterFirstApproval =
      revisionsCreatedAfterFirstApproval.length
    if (numRevisionsAfterFirstApproval) {
      icons.push({
        icon: '$comments',
        color: 'blue',
        message:
          pluralize(numRevisionsAfterFirstApproval, 'revision') +
          ' created after first customer approval'
      })
    }
    const hasAddOnTitleContaining = (titleSubstring: string) =>
      addOns.some((addOn) => (addOn.name as string).includes(titleSubstring))
    if (hasAddOnTitleContaining('Double Thick')) {
      icons.push({
        icon: '$layer-plus',
        message: `Double Thick`
      })
    } else if (hasAddOnTitleContaining('Triple Thick')) {
      icons.push({
        icon: '$layer-group',
        message: `Triple Thick`
      })
    }
    // Approximates rules from ShipmentAnalysis.cls Apex code from sfdc, but using code instead of format enum name
    const hasCircle = isCircular(itemCode)
    const hasRoundCorners = addOns.some(
      (addOn: Proof) => addOn.name === 'Round Corners'
    )
    if (hasCircle || hasRoundCorners) {
      icons.push({
        icon: '$cut',
        message:
          (hasCircle ? 'Circle' : 'Round Corners') + ' (requires cutting)'
      })
    }
    if (proof) {
      const foilColor = codeFoilColor(proof.code as string)
      if (foilColor) {
        /**
         * Color swatched from our on-site foil icons
         */
        let color
        switch (foilColor) {
          case 'GOLD':
            color = '#EAD27B'
            break
          case 'SILVER':
            color = '#D3D3D3'
            break
          case 'ROSE':
            color = '#D4A68F'
            break
        }
        icons.push({
          icon: '$sparkles',
          message: `Foil (${foilColor.toLowerCase()})`,
          color
        })
      }
    }

    // If `deliveryType` is available, this is an order item, and only 1 type can exist
    if (deliveryType) {
      // Put order item delivery type icon last so it sticks out if there's a similar shipped item
      switch (deliveryType) {
        case 'Mail & Message':
          icons.push({
            // Also tried '$mailbox', but it's harder to identify at a small size
            icon: '$mail-bulk',
            message: 'Mailed For You'
          })
          break
        case 'Address & Message':
          icons.push({
            icon: '$address-card',
            message: 'Shipped To You with recipient addressing'
          })
          break
      }
    } else {
      // Unpaid proofs won't have a delivery type, or individual items for each type.
      // However, we can analyze addOns to know what the delivery type(s) of the order item(s) would be
      // if the proofs were ordered.
      if (hasAddOnTitleContaining('Mailed Envelopes')) {
        icons.push({
          // Also tried '$mailbox', but it's harder to identify at a small size
          icon: '$mail-bulk',
          message: 'Mailed For You'
        })
      }
      if (hasAddOnTitleContaining('Recipient Addressed Envelopes')) {
        icons.push({
          icon: '$address-card',
          message: 'Shipped To You with recipient addressing'
        })
      }
    }

    if (baseProof) {
      icons.push({
        icon: '$copy',
        message: 'Reorder'
      })
    }

    // TODO: Implement inline UI to take specific actions and take as prop
    const actions: ListItemAction[] = [
      // {
      //   title: 'Add Proof Special Instruction',
      //   link: 'https://www.youtube.com/watch?v=gSLIdT4EBlw'
      // }
    ]

    return {
      get proofUrl() {
        const proofWithRevisions = baseProof || proof
        if (!proofWithRevisions) {
          // Expected to be an Static / non-personalized order item without a proof
          return null
        }
        const bestRevision = proofWithRevisions.revisions?.find(
          (revision: ProofRevision) =>
            revision.id === proofWithRevisions.bestRevisionId
        )
        // TODO: Consider adding a query for surfaces so we can show the first *non-hidden* surface
        // TODO: Switch to the proof rendering API that's used in My Account and remove proofUrl, rawProofUrl from SOQL
        // rawProofUrl is a fallback for older proofs that don't have proofUrl populated
        return bestRevision?.proofUrl || bestRevision?.rawProofUrl
      },
      actions,
      icons,
      formatDateShort,
      formatDateDistanceToNowIfRecent,
      salesforceUrl
    }
  }
})
