import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import VueCompositionAPI from '@vue/composition-api'
import Vue from 'vue'
import WrappedComponent from 'vue-wrapped-component'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'

// Standardize env name with what we use for other apps
const env = process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
// Allows Datadog to run when the site is loaded from another one (iframe). Implies `useSecureSessionCookie`.
const useCrossSiteSessionCookie =
  window.location.protocol === 'https:' || window.self !== window.top

const DATADOG_CLIENT_TOKEN = 'puba4af61fd01f65508b9155a6c6e210de8'
const SERVICE = 'hashi'
const version = process.env.VUE_APP_COMMIT_REF || 'dev'

datadogRum.init({
  applicationId: '511edfbb-6194-42ce-a45b-1587ee4b84b7',
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: SERVICE,
  env,
  version,
  trackInteractions: true,
  allowedTracingOrigins: ['https://www.paperculture.com'],
  useCrossSiteSessionCookie
})

datadogLogs.init({
  clientToken: DATADOG_CLIENT_TOKEN,
  service: SERVICE,
  env,
  version,
  useCrossSiteSessionCookie
})

Sentry.init({
  dsn:
    'https://dcd743b993ad4966902fb4b29dc3a14e@o54878.ingest.sentry.io/5664170',
  Vue: Vue,
  environment: env,
  // Sentry will call original Vue's logError function as well so that errors show up in the console
  logErrors: true,
  // Enable performance monitoring
  integrations: [new Integrations.BrowserTracing()],
  tracingOptions: {
    trackComponents: true
  },
  // 100% sampling isn't generally not recommended for production, but it's OK internally
  tracesSampleRate: 1.0,
  // Release tracking. Prefixed by project name because releases are global to all projects in Sentry.
  // https://docs.sentry.io/platforms/javascript/configuration/releases/
  release: `hashi@${version}`
})

clarity('set', 'env', env)

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(WrappedComponent)

new Vue({
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
