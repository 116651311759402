









































import { defineComponent, PropType } from '@vue/composition-api'

import { TooltipParams } from '@/components/mixins/analytics'
import { iconValidator } from '@/components/mixins/icons'
import Tooltip from '@/components/Tooltip.vue'

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    color: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    textColor: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    icon: {
      type: String as PropType<string | undefined>,
      default: undefined,
      validator: iconValidator
    },
    // Automatically enable font-weight-bold on non-white solid background in improve legibility
    autoBold: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    tooltipColor: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    tooltipAnalyticsName: {
      type: String,
      default: 'ChipLabel'
    }
  },
  setup({ icon, color }) {
    const tooltipAnalyticsParams: TooltipParams = {
      // Only add if defined
      ...(icon ? { icon } : {}),
      ...(color ? { color } : {})
    }
    return {
      tooltipAnalyticsParams
    }
  }
})
