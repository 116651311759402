var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VListItem',[_c('VListItemContent',[_c('div',{staticClass:"item-content d-flex flex-no-wrap justify-space-between align-center"},[_c('div',{staticClass:"item-summary"},[_c('VListItemTitle',[_c('OverallStatusIcon',{attrs:{"status":_vm.overallStatus,"days-late":_vm.daysLate,"proof-last-modified-date":(_vm.proof && _vm.proof.lastModifiedDate) || null,"tooltip-analytics-name":"`Overall ${analyticsName.toLowerCase()} status`"}}),_c('Tooltip',{attrs:{"top":"","analytics-name":"`${analyticsName}` name","analytics-params":{
              type: _vm.isAddOn ? 'Add-On' : 'Main'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('VBadge',{staticClass:"mt-0",attrs:{"value":_vm.addOns.length,"content":_vm.addOns.length,"color":"grey","bordered":"","inline":""}},[_c('a',_vm._g(_vm._b({class:[
                    'text-truncate',
                    {
                      'text-decoration-none text--darken-1 grey--text': _vm.isAddOn
                    }
                  ],staticStyle:{"max-width":"146px"},attrs:{"href":_vm.salesforceUrl(_vm.salesforceId),"target":"_blank","rel":"noopener"},domProps:{"textContent":_vm._s(_vm.shortTitle)}},'a',attrs,false),on))])]}},{key:"tooltip",fn:function(){return [_vm._v(" Proof ID: "+_vm._s(_vm.proofId.substr(0, 5))+" • "+_vm._s(_vm.shortTitle)+" ")]},proxy:true}])})],1),(_vm.$slots.default)?_c('VListItemSubtitle',{class:_vm.isAddOn ? 'text--darken-1 grey--text' : 'text--primary'},[_vm._t("default")],2):_vm._e(),_c('VListItemSubtitle',[_c('IconsWithTooltips',{attrs:{"icons":_vm.icons,"tooltip-analytics-name":"`${analyticsName} icon"}})],1)],1),(_vm.proofUrl)?_c('VImg',{attrs:{"src":_vm.proofUrl.replace(
            'http://storage.paperculture.com',
            'https://storage.googleapis.com/storage.paperculture.com'
          ),"contain":"","height":"50","max-width":"50","max-height":"50"}}):_vm._e()],1)]),_c('ListItemActions',{attrs:{"actions":_vm.actions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }